import {
    Component,
    OnInit, Injectable
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PhoneMaskService} from "../../service/phoneMask.service";
import {LanguageService} from "../../modules/language/language.service";
import {HomeService} from "../../service/home.service";
import {ActivatedRoute} from "@angular/router";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {AUTH_CODES} from "../../config/auth-codes.config";
import {CurrencyService} from "../../modules/currency/currency.service";
import {CASH_APP_PAYMENT_AMOUNT, PROJECT_URL} from "../../config/project";
import {CHECKOUT_CHARGE_PAYMENT_TYPE} from "../../shared/checkout-charge-popup/checkout-charge-popup.config";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
@Injectable({providedIn: 'root'})
export class LoginComponent implements OnInit {
    partnerLogo = '../assets/images/logo.png';

    loginForm: FormGroup;
    keywordsForm: FormGroup;
    step = 1;

    languageChangeSubscription;
    phoneMask;

    terms: string;
    isTermsExpandedShown = false;

    games = [];
    showCashAppPopup = false;
    cashAppPopupData;
    cashappInfo;

    constructor(private languageService: LanguageService,
                private phoneMaskService: PhoneMaskService,
                private loaderService: LoaderService,
                private currencyService: CurrencyService,
                private homeService: HomeService,
                private popupComponent: PopupComponent,
                private activatedRoute: ActivatedRoute,
                private formBuilder: FormBuilder,) {}
    ngOnInit(): void {
        this.languageChangeSubscription = this.languageService.languageIsUpdated.subscribe(val => {
            if (val) {
                setTimeout(() => {
                    this.getPromoCodeAndPageInfo();
                });
            }
        });

        this.phoneMask = this.phoneMaskService.setPhoneMask();

        this.loginForm = this.formBuilder.group({
            promo: [null, Validators.required],
            email: [null, Validators.required],
            phone: [null, Validators.required]
        });

        this.keywordsForm = this.formBuilder.group({
            textarea: [null, Validators.required],
            terms: [false, Validators.required],
        });

        this.getPromoCodeAndPageInfo();
    }

    getPromoCodeAndPageInfo(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            const promoCode = params.promo ? params.promo : '';
            if (promoCode) {
                this.loginForm.controls.promo.setValue(promoCode);
            }
            this.getPageInfo(promoCode);
        });
    }

    getPageInfo(promoCode): void {
        this.homeService.getPageInfo(promoCode).subscribe(data => {
            try {
                if (data.status) {
                    this.games = data.games;
                    this.terms = data.terms;
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide()
        });
    }

    showCheckoutTerms(): void {
        document.body.classList.add('hide-menu');
        this.isTermsExpandedShown = true;
    }

    closeTermsExpanded(): void {
        document.body.classList.remove('hide-menu');
        this.isTermsExpandedShown = false;
    }

    onPhoneBlur(): void {
        if (!this.loginForm.controls.phone.value) {
            this.loginForm.controls.phone.setValue(null)
        }
    }

    onContinue(): void {
        const email = this.loginForm.controls.email.value;
        const phone = this.loginForm.controls.phone.value;
        const promoCode = this.loginForm.controls.promo.value;

        this.homeService.getUserStatus(email, phone, promoCode).subscribe(data => {
            try {
                if (data.status) {
                    if (data.code && (data.code === AUTH_CODES.userNotExists)) {
                        this.step = 2;
                        this.cashappInfo = data.cashappInfo;
                        this.keywordsForm.controls.textarea.setValue("It's a beautiful day in Eden!")
                    } else {
                        this.loaderService.hideAndShowTryAgain();
                    }
                    this.loaderService.hide();
                } else {
                    this.checkIfUserExists(data);
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        });
    }

    checkIfUserExists(data) {
        const email = this.loginForm.controls.email.value;
        const phone = this.loginForm.controls.phone.value;
        if (data.code && (data.code === AUTH_CODES.userExists)) {
            if (phone) {
                window.location.href = PROJECT_URL + `/?phone=${phone}`;
            } else if (email) {
                window.location.href = PROJECT_URL + `/?email=${email}`;
            }
        } else {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: data.message});
        }
    }

    formatCurrency(amount): string {
        return this.currencyService.formatCurrency(amount);
    }

    isContinueButtonDisabled(): boolean {
        return !this.loginForm.controls.promo.value ||
                (this.loginForm.controls.phone.invalid && this.loginForm.controls.email.invalid);
    }

    isDepositButtonDisabled(termsAccept): boolean {
        return this.isContinueButtonDisabled() ||
               !this.keywordsForm.controls.textarea.value ||
               !termsAccept.checked
    }

    onShowCashAppPopup() {
        const inputAmount = CASH_APP_PAYMENT_AMOUNT;

        this.showCashAppPopup = true;
        this.cashAppPopupData = {
            data: {
                payment: CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp,
                inputAmount: inputAmount
            }
        };
        this.cashAppPopupData.data.cashappInfo = this.cashappInfo;

        // this.openNFTPopup({showNFTPopup: true, data: this.cashAppPopupData});
    }

    onDepositConfirm($event) {
        const email = this.loginForm.controls.email.value;
        const phone = this.loginForm.controls.phone.value;
        const promoCode = this.loginForm.controls.promo.value;
        const keywords = this.keywordsForm.controls.textarea.value;
        const token = $event.token;

        this.homeService.payWithCashApp(email, phone, promoCode, keywords, token).subscribe(data => {
            this.setDepositConfirmResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            //this.loaderService.hide();
        });
    }

    setDepositConfirmResult(data) {
        try {
            if(data.status === true) {
                window.location.href = this.getAppLink(data.token);
            } else if (data.status === false) {
                this.checkIfUserExists(data);
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onCashAppPopupClose() {
        document.body.classList.remove('hide-menu');
        this.showCashAppPopup = false;
    }

    getAppLink(token) {
        return PROJECT_URL + '/senetpro?scrollToGames=1&token=' + token;
    }
}

